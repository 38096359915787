.app_header {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    height: 72px;
    border-bottom: 1px solid #E4E8E7;
}

.app_logo {
    padding-left: 16px;
    height: 100%;
    display: flex;
    align-items: center;
}
.app_logo img {
    height: 45px;
}
.app_header_title {
    color: #717473;
    font-size: 20px;
    margin-left: 30px;
    flex: 1;
}

.app_nav_menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.app_nav_menu li + li {
    margin-left: 30px;
}

.app_nav_menu li a {
    color: #666666;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
}

.app_nav_menu li a:hover {
    color: #4272d7;
}

.app-topmenu-UTC {
    display: grid;
    grid-area: topmenu-UTC;
    grid-template-areas:  "app-topmenu-UTC-icon app-topmenu-UTC-time"
    "app-topmenu-UTC-icon app-topmenu-UTC-date";
    padding-right: 16px;
    white-space: nowrap;
    border-left: 1px solid #E4E8E7;
    padding-left: 16px;
    height: 100%;
}

.app-topmenu-UTC-icon {
    grid-area: app-topmenu-UTC-icon;
    width: 32px;
    padding-right: 12px;
}

.app-topmenu-UTC-icon img {
    width: 100%;
    height: 100%;
}

.app-topmenu-UTC-time {
    grid-area: app-topmenu-UTC-time;
    font-size: 14px;
    font-weight: 500;
    color: #717473;
    align-self: flex-end;
}

.app-topmenu-UTC-date {
    grid-area: app-topmenu-UTC-date;
    font-size: 11px;
    font-weight: 300;
    align-self: flex-start;
    line-height: 11px;
    padding-bottom: 4px;
}

.app-topmenu-loggedin {
    grid-area: topmenu-loggedin;
    display: grid;
    grid-template-areas:  "app-topmenu-loggedin-icon app-topmenu-loggedin-name"
    "app-topmenu-loggedin-icon app-topmenu-loggedin-logout";
    padding-right: 16px;
    white-space: nowrap;
    border-left: 1px solid #E4E8E7;
    padding-left: 16px;
    height: 100%;
}

.app-topmenu-loggedin-icon {
    grid-area: app-topmenu-loggedin-icon;
    width: 32px;
    padding-right: 12px;
}

.app-topmenu-loggedin-icon img {
    width: 100%;
    height: 100%;
}

.app-topmenu-loggedin-username {
    grid-area: app-topmenu-loggedin-name;
    font-size: 14px;
    font-weight: 500;
    color: #717473;
    align-self: flex-end;
    white-space: nowrap;
}

.app-topmenu-loggedin-logout {
    grid-area: app-topmenu-loggedin-logout;
    font-size: 13px;
    font-weight: 400;
    color: #00B5C2;
    cursor: pointer;
}

.app-topmenu-loggedin-logout:hover {
    color: #009AA5;
}