
.app_container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app_content {
    display: flex;
    flex-grow: 1;
}

.app_sidecontent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
	position: relative;
}

/*  semantic ui components -------------------------------------------------------------------------------------------*/

/*  form elements ----------------------------------------------------------------------------------------------------*/

.ui.form .fields {
	margin: 0;
}

.ui.form .fields>.field {
	padding: 0 0 16px 0;
}

.ui.form .field>label {
	font-size: 11px;
	font-weight: 500;
	color: #cfd3d5;
	margin: 0 0 3px 0;
}

.ui.form .field.field input,
.ui.form .field.field textarea {
	font-family: "Roboto", sans-serif;
	padding-top: 9px;
	padding-bottom: 9px;
	font-size: 13px;
	line-height: 18px;
	font-weight: 500;
	color: #4b4f4e;
	border-color: #ebebeb;
	box-shadow: 0 0 0 100px #fbfbfb inset;
	border-radius: 3px;
}

.ui.form .field.field input:-webkit-autofill::first-line {
	font-size: 13px;
	font-weight: 500;
	color: #4b4f4e;
}

.ui.form .field.field input:-webkit-autofill,
.ui.form .field.field input:-webkit-autofill:hover,
.ui.form .field.field input:-webkit-autofill:focus,
.ui.form .field.field textarea:-webkit-autofill,
.ui.form .field.field textarea:-webkit-autofill:hover,
.ui.form .field.field textarea:-webkit-autofill:focus,
.ui.form .field.field select:-webkit-autofill,
.ui.form .field.field select:-webkit-autofill:hover,
.ui.form .field.field select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 100px #fbfbfb inset;
	-webkit-text-fill-color: #4b4f4e;
	border-color: #ebebeb;
}

.form-spacer {
	width: 8px;
}

/*  label ------------------------------------------------------------------------------------------------------------*/

label {
	font-size: 11px;
	font-weight: 500;
	color: #cfd3d5;
	margin: 0 0 3px 0;
}

/*  inputs -----------------------------------------------------------------------------------------------------------*/

.ui.input>input {
  padding-top: 10px;
  padding-bottom: 12px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: #4b4f4e;
  border-color: #ebebeb;
  box-shadow: 0 0 0 100px #fbfbfb inset;
  border-radius: 3px;
  min-height: unset;
}


.ui.input>input::placeholder {
  font-size: 13px;
  font-weight: 500;
  color: #c6cacb;
}

.ui.action.input:not([class*="left action"])>.button:last-child,
.ui.action.input:not([class*="left action"])>.buttons:last-child>.button,
.ui.action.input:not([class*="left action"])>.dropdown:last-child {
  border-radius: 0 3px 3px 0;
  border: 1px solid #e5e7e8;
  border-left: none;
  color: #00adbb;
  font-weight: 700;
}

.ui.action.input:not([class*="left action"])>input {
  border-radius: 3px 0 0 3px;
}

/*  buttons ----------------------------------------------------------------------------------------------------------*/

.ui.action.input>.button {
	background-color: #ffffff;
	border: 1px solid #BBBFBE;
	font-size: 11px;
	font-weight: 700;
	color: #676C6B;
}

.ui.button {
	font-family: "Open Sans", sans-serif;
	border-radius: 3px;
	margin: 0 0 0 4px;
	font-size: 11px;
	padding: 10px 16px 10px 16px;
}

.ui.button:first-of-type {
	margin: 0;
}

.ui.button.btn_secondary {
	color: #52c7d1;
	background-color: #ffffff;
	border: 1px solid #e5e7e8;
	font-weight: 700;
}

.ui.button.btn_secondary:hover {
	color: #41ADB4;
}

.ui.button.btn_primary {
	background-color: #52c7d1;
	color: #ffffff;
	border-color: #52c7d1;
	font-weight: 700;
	opacity: 0.5;
	cursor: default;
}

.ui.button.btn_primary.btn_active {
	opacity: 1;
	cursor: pointer;
}

.ui.button.btn_primary.btn_active:hover {
	background-color: #41ADB4;
	border-color: #41ADB4;
}

.ui.button.btn_secondary.btn_disabled {
	background-color: #E4E5E4;
	border-color: #B1B3B2;
	color: #717473;
	cursor: default;
}

.ui.primary.button,
.ui.primary.buttons .button,
.ui.loading.loading.loading.loading.loading.loading.button {
	font-family: "Roboto", sans-serif;
	background-color: #2ea3a6;
	color: #ffffff;
	text-shadow: none;
	background-image: none;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
	background-color: #2ea3a6;
}

.ui.button.btn_tertiary {
	background-color: transparent;
	border: none;
	font-size: 11px;
	font-weight: 700;
	color: #52c7d1;
	padding: 8px 16px 8px 0;
	width: fit-content;
}

.ui.button.btn_tertiary:hover {
	color: #42afb6;
}

.ui.button.btn_tertiary .btn_icon {
	width: 16px;
	height: 16px;
	margin: -4px 4px -4px 0;
}

.ui.button.activestate {
	background-color: #52c7d1;
	border-color: #52c7d1;
	color: #ffffff;
	font-weight: 700;
}

	.ui.button.btn_icononly {
	border: 1px solid #e9eaeb;
	cursor: pointer;
	background-color: #ffffff;
	padding: 9px 18px;
	height: 38px;
}

.ui.button.btn_icononly .btn_icon {
	margin-right: -8px;
}

.ui.button .btn_icon {
	width: 16px;
	height: 16px;
	margin: -4px 10px -3px -6px;
}

/*  checkbox ---------------------------------------------------------------------------------------------------------*/

.ui.radio.checkbox {
  min-height: 15px;
  margin-right: 16px !important;
}

.ui.radio.checkbox label {
  padding-left: 20px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #676C6B !important;
}

.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
  background-color: #00adbb !important;
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #00adbb !important;
}

.ui.toggle.checkbox label {
  font-size: 13px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  color: #8a8d8c !important;
}

.ui.toggle.checkbox input:checked~label,
.ui.toggle.checkbox input:focus:checked~label {
  font-size: 13px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
}

.ui.toggle.checkbox.th_toggle input:checked~.box:before,
.ui.toggle.checkbox.th_toggle input:checked~label:before {
  opacity: .95;
  color: #4b4f4e !important;
}

.ui.toggle.checkbox.th_toggle input:focus:checked~.box,
.ui.toggle.checkbox.th_toggle input:focus:checked~label {
  opacity: .95;
  color: #4b4f4e !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
  opacity: .95;
  color: #4b4f4e !important;
}

/*  dropdown ---------------------------------------------------------------------------------------------------------*/

.ui.selection.dropdown {
  font-family: "Roboto", sans-serif !important;
  padding-top: 10px !important;
  padding-bottom: 12px !important;
  font-size: 13px !important;
  line-height: 14px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
  border-color: #ebebeb !important;
  box-shadow: 0 0 0 100px #fbfbfb inset!important;
  border-radius: 3px !important;
  min-height: unset !important;
  min-width: 60px !important;
}

.ui.selection.multiple.dropdown {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.ui.search.dropdown>input.search {
  left: 0 !important;
}

.ui.selection.dropdown>.delete.icon,
.ui.selection.dropdown>.dropdown.icon,
.ui.selection.dropdown>.search.icon {
  line-height: unset;
  padding: 11px 7px;
}

.ui.selection.active.dropdown .menu {
  border-color: #ebebeb !important;
}

.ui.selection.dropdown .menu>.item {
  padding: 8px 16px !important;
}

.ui.dropdown .menu .active.item {
  font-weight: 700 !important;
  color: #4b4f4e !important;
}

.ui.dropdown .menu>.item {
  color: #4b4f4e;
  font-size: 13px;
  font-weight: 400;
}


/*  progress bars ----------------------------------------------------------------------------------------------------*/

.ui.progress {
	background: #ffffff;
	border: 1px solid #ebebeb;
	box-shadow: 0 0 0 100px #fbfbfb inset;
	border-radius: 3px;
}

.ui.progress .bar {
	background: #69b536 !important;
	border-radius: 2px !important;
	height: 100%;
}

/*  pagination -------------------------------------------------------------------------------------------------------*/

.pg_group {
	display: flex;
	justify-content: flex-end;
}

.pg_group .ui.form .fields>.field {
	padding: 0 0 0 0;
}

.pg_group .pg_results {
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
	color: #4b4f4e;
	padding: 8px 8px 0 0;
	display: flex;
}

.pg_group .pg_results_emph {
	padding: 0 3px 0 3px;
	font-weight: 700;
	color: #4b4f4e;
}

.pg_group .pg_show {
	width: 60px;
}

.pg_group .ui.pagination.menu {
	box-shadow: none;
	border: 1px solid #ebebeb;
	border-radius: 3px;
	height: 38px;
	margin: 0 0 0 4px;
}

.pg_group .ui.pagination.menu .item {
	font-size: 13px;
	line-height: 14px;
	font-weight: 500;
	color: #4b4f4e;
	padding: 12px 16px 12px;
	min-width: unset;
}

.pg_group .ui.pagination.menu .item:focus {
	outline: none;
}

.pg_group .ui.pagination.menu .active.item {
	background-color: #fbfbfb;
	color: #00adbb;
}

.pg_group .pg_jump {
	width: 80px;
}

.pg_group #btn_refresh {
	margin: 0 0 0 4px;
}

.pg_group .pg_refresh:hover {
	background: rgba(0,0,0,.03);
}

.pg_group .pg_refresh.pg_loading {
	opacity: 0.5;
}

.pg_group .pg_download {
	margin: 25px 0px 16px 4px;
}


/*  popup -----------------------------------------------------------------------------------------------------------*/

.ui.popup {
	padding: 8px 0 8px 0;
	border-color: #ebebeb;
	font-size: 13px;
	font-weight: 500;
	color: #4b4f4e;
}

.ui.popup:before {
	box-shadow: -1px -1px 0 0 #e4e4e3;
}


/*  CONTENT --------------------------------------------------------------------------------------------------------- */
.content {
	display: flex;
	flex-direction: column;
}

/* CONTENT TOP MENU */
.content_top_menu {
    background-color: #F8F9F9;
    display: flex;
    align-items: center;
    padding: 11px 0 13px 16px;
    border-bottom: 1px solid #E4E8E7;
    border-top: 2px solid #F4F6F5;
}

.content_top_menu_icon {
    font-size: 24px;
    color: #585b5a;
    line-height: 1;
}

.content_top_menu_icon i {
    margin-right: 8px;
}

.content_top_menu_title {
    font-size: 16px;
    font-weight: 500;
    color: #4b4f4e;
}

.content_top_menu .label {
    margin-left: 10px;
    font-size: 11px;
    padding: 4px 6px 3px;
}

/* CONTENT WRAPPER */
.content_wrapper {
	padding: 24px;
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 24px;
	align-items: start;
}

.content_card {
	background-color: #fff;
	border: 1px solid #e5e7e8;
	border-radius: 4px 4px 0 0;
	width: 100%;
	display: grid;
	grid-template-rows: 60px auto;
}

.content-card-header {
	font-size: 16px;
	font-weight: 700;
	color: #49595f;
	align-items: center;
	padding: 0 8px 4px 24px;
	display: flex;
	flex-direction: row;
	border-bottom: 1px dashed #E8E9E9;
}

.content-card-header-title {
	flex-grow: 1;
}

.content-filter {
	padding: 24px 8px 8px 24px;
	display: flex;
	justify-content: flex-end;
}

.content-filter-item.item-search .ui.input > input {
	width: 320px;
}

.content-filter-archive {
    display: flex;
}

.content-filter-item.item-pagination {
  flex-grow: 1;
}

.content-card-footer {
  border-top: 1px solid #E8E9E9;
  height: 56px;
  background-color: #F8F9F9;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.content-card-footer-lastmodified {
  opacity: 0.75;
  display: grid;
  grid-template-columns: 22px auto;
  padding-left: 8px;
  align-items: center;
}

.content-card-footer-lastmodified_text {
  font-size: 11px;
  font-weight: 500;
  color: #676C6B;
  line-height: 11px;
}

.content-card-footer-lastmodified_icon {
  width: 19px;
  height: 19px;
  float: left;
  cursor: pointer;
  opacity: .8;
}

.content-card-footer-lastmodified_icon img {
  height: 100%;
  width: 100%;
}


.content-card-footer-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}

.content-card-footer-buttons .ui.button {
  background-color: #ffffff;
  border: 1px solid #E8E9E9;
  font-size: 11px;
  font-weight: 500;
  color: #676C6B;
  padding: 10px 16px 10px 16px;
}



/*  pagination -------------------------------------------------------------------------------------------------------*/

.pg_group {
  display: flex;
  justify-content: flex-end;
}

.pg_group .ui.form .fields>.field {
  padding: 0 0 0 0 !important;
}

.pg_group .pg_results {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 20px;
  color: #4b4f4e !important;
  padding: 8px 8px 0 0;
  display: flex;
}

.pg_group .pg_results_emph {
  padding: 0 3px 0 3px;
  font-weight: 700;
  color: #4b4f4e;
}

.pg_group .pg_show {
  width: 60px;
}

.pg_group .ui.pagination.menu {
  box-shadow: none !important;
  border: 1px solid #ebebeb !important;
  border-radius: 3px !important;
  height: 38px;
  margin: 0 0 0 4px;
}

.pg_group .ui.pagination.menu .item {
  font-size: 13px !important;
  line-height: 14px !important;
  font-weight: 500 !important;
  color: #4b4f4e !important;
  padding: 12px 16px 12px !important;
  min-width: unset !important;
}

.pg_group .ui.pagination.menu .item:focus {
  outline: none !important;
}

.pg_group .ui.pagination.menu .active.item {
  background-color: #fbfbfb !important;
  color: #00adbb !important;
}

.pg_group .pg_jump {
  width: 80px;
}

.pg_group #btn_add_row {
  margin: 0 0 0 4px;
}

.pg_group .btn_add_row:hover {
  background: rgba(0,0,0,.03);
}

.pg_group .pg_refresh.pg_loading {
  opacity: 0.5;
}

.pg_group .pg_download {
  margin: 25px 0px 16px 4px;
}


/*  table ------------------------------------------------------------------------------------------------------------*/
.tablewrapper {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 16px;
		position: relative;
}

.emptytable {
	background-color: #fdfdfd;
	display: flex;
	width: 100%;
	border-bottom: 1px solid #ebebeb;
	padding: 6px 0 6px 0;
	align-items: center;
	justify-content: center;
	color: #cdcecd;
}

.emptytable img {
	width: 18px;
	height: 18px;
	margin-right: 8px;
}

.tablewrapper .ui.table {
    margin: 16px 0 0 0;
}

.ui.table {
    border: none;
    background: none;
    font-size: 13px;
    font-weight: 400;
    color: #4b4f4e;
    margin: 16px 0;
    border-radius: 0;
}

.ui.table thead th {
    background: none !important;
	border-left: none !important;
	border-right: none;
    font-size: 11px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    color: #cfd3d5 !important;
    padding: 4px 8px 0 8px !important;
    cursor: pointer;
	height: 31px;
	text-transform: uppercase;
}

.th_language .ui.label {
	font-size: 11px;
	padding: 3px 5px 3px;
	margin-left: 10px;
}

.tbl_loading {
    opacity: .5;
}

.ui.table tr {
    cursor: pointer;
    height: 31px;
}

.ui.table tbody tr:hover {
    background-color: #f6fcfd;
    color: #47bbc3;
}

.ui.table tr td {
	border-top: 1px solid #ebebeb;
	vertical-align: top;
	-ms-word-wrap: break-word;
    word-wrap: break-word;
}

.ui.table thead th.th_actions,
.ui.table tbody td.td_actions {
	text-align: center;
	width: 80px;
}

.ui.table thead th.th_namespace {
	width: 190px;
}

.ui.table thead .th_created_on,
.ui.table tbody .td_created_on {
	width: 110px;
	text-align: center;
}

.ui.table thead .th_history,
.ui.table tbody .td_history {
	width: 70px;
	text-align: center;
}

.ui.table tbody .td_history img {
	width: 18px;
}

.ui.table tbody td.td_actions img {
	width: 18px;
}

.language_text .ui.label {
	font-size: 11px;
	padding: 3px 5px;
}

.editable_form {
	display: none;
	max-width: 360px;
}

.editable_form_show .editable_form {
	display: block;
}

.editable_form_show .language_text {
	display: none;
}

.editable_form_textarea {
	padding-bottom: 5px;
}

.editable_form .editable_form_submit .ui.button {
	padding: 8px 10px;
}

/*  message ----------------------------------------------------------------------------------------------------------*/

.message {
	border: 1px solid #e5e7e8;
	border-radius: 3px;
	padding: 8px;
	margin-bottom: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.message_icon {
	padding: 0 12px 0 4px;
	display: flex;
}

.message_icon img {
	width: 24px;
	height: 24px;
	opacity: .8;
}

.message_text_wrapper {
	display: flex;
	flex-direction: column;
}

.message_text {
	font-size: 13px;
	line-height: 16px;
}

.message_text.cause {
	font-weight: 400;
	padding-top: 4px;
	padding-bottom: 4px;
}

.message_text.resolution {
	font-weight: 500;
	padding-top: 4px;
	padding-bottom: 4px;
}

.message.warning {
	color: #e0454f;
	border-color: #ed9399;
	background-color: #fef8f8;
}

.message.info {
	color: #42afb6;
	border-color: #66d2da;
	background-color: #f8fdfd;
}

.message.success {
	color: #69b536;
	border-color: #a8d48a;
	background-color: #f9fcf7;
}

/*  modals -----------------------------------------------------------------------------------------------------------*/

.modal-header {
	font-size: 20px;
	font-weight: 700;
	letter-spacing: -.25px;
	display: flex;
	align-items: center;
	padding: 16px;
	border-bottom: 1px dashed #ebebeb;
	height: 56px;
}

.modal-header-title {
	flex-grow: 1;
	padding-right: 48px;
}

.modal-header-close {
	opacity: .75;
	cursor: pointer;
	margin: -16px -16px -16px 0;
	padding: 14px;
}

.modal-header-close:hover {
	opacity: 1;
}

.modal-header-close img {
	width: 18px;
	height: 18px;
}

.modal-content {
	padding: 16px;
	font-size: 13px;
	line-height: 17px;
}

.modal-footer {
	/*border-top: 1px solid #E8E9E9;*/
	height: 56px;
	background-color: #F8F9F9;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'56'%20height%3D'100'%3E%0A%3Crect%20width%3D'56'%20height%3D'100'%20fill%3D'%23F8F9F9'%2F%3E%0A%3Cpath%20d%3D'M28%2066L0%2050L0%2016L28%200L56%2016L56%2050L28%2066L28%20100'%20fill%3D'none'%20stroke%3D'%23fdfdfd'%20stroke-width%3D'2'%2F%3E%0A%3Cpath%20d%3D'M28%200L28%2034L0%2050L0%2084L28%20100L56%2084L56%2050L28%2034'%20fill%3D'none'%20stroke%3D'%23fdfdfd'%20stroke-width%3D'2'%2F%3E%0A%3C%2Fsvg%3E");

}

.modal-footer-buttons {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
	padding-right: 8px;
}