.app_sidebar {
    background-color: #ffffff;
    border-top: 2px solid #F8F9F9;
    border-right: 1px solid #E4E8E7;
    padding: 0 16px 0px 16px;
    width: 200px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.app_sidenav_group {
    display: flex;
    flex-direction: column;
    border-top: 1px dashed #e4e8e7;
    margin-bottom: 16px;
}

.app_sidenav_group:first-of-type {
    border-top: none;
}

.app_sidenav_group_header {
    font-size: 12px;
    font-weight: 700;
    color: #C6CECC;
    padding: 16px 0 8px 0;
}

.sidenav_item {
    font-size: 14px;
    font-weight: 400;
    color: #8a8d8c;
    padding: 4px 0 4px 0;
    display: flex;
    align-items: center;
}


.sidenav_item .sidenav_item_text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidenav_item .sidenav_item_text .label {
    font-size: 11px;
    font-weight: 400;
    padding: 4px 5px 3px;
}

.available_locales .sidenav_item .sidenav_item_text {
    text-transform: uppercase;
    
}

.locales_stats .sidenav_item:hover {
    color: #00adbb;
}

.locales_stats .sidenav_item {
    border-right: 4px solid transparent;
    margin-right: -16px;
    padding-right: 12px;
}

.locales_stats .sidenav_item.active {
  border-right-color: #52c7d1;
  color: #00adbb;
}

.locales_stats .sidenav_item .sidenav_item_text .entries_count {
    color: #C6CECC;
}

.locales_stats .sidenav_item .sidenav_item_icon .icon {
    font-size: 16px;
}