.login_wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #00adbb;
    background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'56'%20height%3D'100'%3E%0A%3Crect%20width%3D'56'%20height%3D'100'%20fill%3D'%2300adbb'%2F%3E%0A%3Cpath%20d%3D'M28%2066L0%2050L0%2016L28%200L56%2016L56%2050L28%2066L28%20100'%20fill%3D'none'%20stroke%3D'%2314b4c0'%20stroke-width%3D'2'%2F%3E%0A%3Cpath%20d%3D'M28%200L28%2034L0%2050L0%2084L28%20100L56%2084L56%2050L28%2034'%20fill%3D'none'%20stroke%3D'%2314b4c0'%20stroke-width%3D'2'%2F%3E%0A%3C%2Fsvg%3E");
}

.TOC {
    color: #363c5b;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    padding-bottom: 24px;
}

.login {
    width: 300px;
    border: 1px solid #eaeaea;
    padding: 28px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

.login_logo {
    text-align: center;
}

.login_logo img {
    width: 90%;
}

.email {
    width: 100%;
}

.password {
    width: 100%;
}

#loginerror.message.warning {
    display: flex;
}

#btn_login {
    margin-left: 0 !important;
    width: 100% !important;
}

#btn_signup {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 8px !important;
    background-color: #ffffff;
    border: 1px solid #73D6DD;
}

.btn_forgotpassword {
    color: #00adbb;
    font-weight: 500;
    font-style: italic;
    cursor: pointer;
    padding: 16px 0px 0px 0px;
}



@media only screen and (max-width: 615px) {

    .login_wrapper .ui.input>input,
    .login_wrapper .ui.form .field.field input {
        font-size: 16px !important;
    }

}