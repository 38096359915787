
#mdl_history {
    width: 1100px !important;
}

#mdl_history .modal-content {
    padding: 0;
}

#mdl_history .content-filter {
    padding-top: 16px;
}